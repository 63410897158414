import Head from 'next/head'
import { Center } from '@mantine/core'

export default function Home() {
  return (
    <>
      <Head>
        <title>Invalid URL</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
        <Center style={{height: "100vh"}}>Invalid URL</Center>
      </main>
    </>
  )
}
